<template>
    <div class="m-haggle m-scroll" ref="scroll" @scroll="handleScroll($event)">
        <!-- 头部导航 -->
        <div class="header" :style="`opacity:${(200 - this.offsetTop)  / 100};`"></div>
        <div class="haggle-top">
            <img :src="haggleInfo.goods.image.remote_url" alt="">
            <!-- <van-swipe :autoplay="5000" lazy-render>
                <van-swipe-item v-for="(item, index) in goods.round_pic_url" :key="index">
                    <img :src="item.url" alt="">
                </van-swipe-item>
            </van-swipe> -->
        </div>
        <div class="goods">
            <!-- 商品价格 -->
            <div class="goods-prices m-padding">
                <div class="price-left">
                <div class="title">Super deal</div>
                <div class="price">
                    Super Price<span>${{haggleInfo.sales_price}}</span><span>${{haggleInfo.price}}</span>
                </div>
                </div>
                <div class="price-right">
                <div class="over">Ends in</div>
                <div class="count">
                    <van-count-down :time="haggleInfo.remaining_time*1000">
                    <template #default="timeData">
                        <span class="block">{{ timeData.days > 9 ? timeData.days : '0' + timeData.days }} Days</span>
                        <span class="colon">:</span>
                        <span class="block">{{ timeData.hours  > 9 ? timeData.hours : '0' + timeData.hours }}</span>
                        <span class="colon">:</span>
                        <span class="block">{{ timeData.minutes > 9 ? timeData.minutes : '0' + timeData.minutes }}</span>
                        <span class="colon">:</span>
                        <span class="block">{{ timeData.seconds > 9 ? timeData.seconds : '0' + timeData.seconds }}</span>
                    </template>
                    </van-count-down>
                </div>
                </div>
            </div>
            <!-- 商品名称 -->
            <div class="goods-title is-margin">
                <div class="title">
                <p class="van-multi-ellipsis--l2">{{haggleInfo.goods_info.name}}</p>
                <div class="rules" @click="rule">
                    <p>rules</p>
                    
                </div>
                </div>
            </div>
            <div class="haggle-content is-margin">
                <div class="haggle-price">
                    <p>Hey there! Do me a favour! </p>
                    <p>Help me to get discount on the Express Pass!</p>
                </div>
                <div class="share-btn" @click="handleHelp" v-if="is_pass">
                    Click here to give a hand
                </div>
                <div class="share-btn" style="background: #ccc" v-else>
                    Click here to give a hand
                </div>
                <div class="buy" @click="$router.push({path:'/en/haggleList'})">
                    <img src="@/assets/bargain/buy_en.png" alt="">
                </div>
            </div>
        </div>
        
        <!-- mooby热销 -->
        <!-- <div class="hot-sale">
            <div class="hot-title">
                <img src="@/assets/bargain/rt.png" alt="">
                Hot Deals
                <img src="@/assets/bargain/lb.png" alt="">
            </div>
            <goods-columns :data="hotProductList" :loading="hloading"></goods-columns>
            <van-loading v-show="hloading" type="spinner" color="#ED2A24" size="6.4vw"></van-loading>
        </div> -->
        <div class="haggle-goods">
            <div class="haggle-title">
                <img src="@/assets/haggle/Group 3086.png" alt="">
                <span>Super Deal Promotion</span>
                <img src="@/assets/haggle/Group 3086.png" alt="">
            </div>
            <van-list 
                v-model="loading"
                :finished="finished"
                finished-text="no more"
                loading-text="Loading..."
                @load="onLoad" >
                <ul class="m-goods-columns m-pad">
                    <li class="goods-columns-1" v-for="(item, index) in goodsList" :key="index">
                        <div class="m-goods">
                            <div class="haggling" v-if="item.show">In progress</div>
                            <img class="goods-img" v-if="item.goods.image" :src="item.goods.image.remote_url" alt="goods">
                            <div class="goods-info">
                                <p class="van-multi-ellipsis--l2">{{item.goods_info.name}}</p>
                                <div class="count">
                                    <span>Ends in</span>
                                    <van-count-down :time="item.remaining_time*1000" format="DD Days HH:mm:ss" />
                                </div>
                                <div class="goods-price">
                                    <div>
                                        <div class="down">Super Price</div>
                                        <p>
                                            <span>${{item.bargain_goods_sku[0].sales_price}}</span>
                                            <span>${{item.bargain_goods_sku[0].goods_sku.price}}</span>
                                        </p>
                                    </div>
                                    <div class="goods-btn">
                                        <router-link v-if="!item.show" :to="{path:`/en/haggle/${item.goods_id}`,query:{id:item.id}}" >Initiate</router-link>
                                        <router-link v-else :to="{path:`/en/haggleDetail/${item.goods_id}`,query:{haggle_id:item.bargain_user_goods_sku.id}}" >Get More</router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </van-list> 
            
        </div>

        <!-- 活动规则 -->
        <m-rule @rule="rule" :ruleShow="ruleShow"></m-rule>

        <!-- 置顶 -->
        <m-scroll-top :show="scrollTop" :dom="$refs.scroll"></m-scroll-top>
    </div>
</template>
<script>
import MScrollTop   from '@/components/zh/m-scroll-top.vue'
// import GoodsColumns from '@/components/en/goods-columns.vue'
import MRule from './rule.vue'
import MixinScroll from '@/untils/js/mixin-scroll.js'
import { hotGoods } from '@/api/zh/index.js'

import { haggle_help, goodsSku_detail, haggle_create, haggle_list } from '@/api/zh/haggle.js'
export default {
    name: 'help',
    mixins: [ MixinScroll ],
    components: { MScrollTop, MRule  },
    data(){
        return {
            offsetTop: 0,
            scrollTop: false,
            time: new Date().getTime(),
            show: true,  // 提示弹窗
            ruleShow: false, //  规则提示窗
            hloading: false,
            haggleInfo: {goods:{image:''},goods_info:{},remaining_time:0},
            page: 0,
            islock: false,
            id: '',
            offPrice: 0, // 优惠金额,
            hotProductList: [],
            is_pass: 0, // 是否可以砍价  默认0  不能砍
            userId: '',
            loading: false,
            finished: false,
            goodsList: [], // 砍价商品列表
        }
    },
    methods:{
        handleScroll(e) {
            this.offsetTop = e.target.scrollTop
            if(e.target.scrollTop >= 550){
                this.scrollTop = true
            }else{
                this.scrollTop = false
            }
            if(e.target.scrollHeight - e.target.clientHeight- e.target.scrollTop <= 0){
                if(!this.islock){
                    e.preventDefault()
                    e.stopPropagation()
                    this.hloading = true
                    this.page++
                    // this.getHotGoods()
                    this.getHaggleData()
                }
            }
        },
        // 是否可以砍价
        isHaggle() {
            haggle_create({bargain_user_goods_sku_id : this.id }).then(res => {
                if(res) {
                   this.is_pass = res.data.is_pass
                }
            })
        },
        // 帮忙砍价
        handleHelp() {
            this.$store.commit('load')
            haggle_help({ bargain_user_goods_sku_id : this.id }).then(res => {
                if(res) {
                    this.$toast(`Good Job! You got your friend a $${res.data.cut_price} off discount`)
                    this.isHaggle()
                }
            }).finally(() => {
                this.$store.commit('unload')
            })
        },
        // 商品信息
        getData() {
           goodsSku_detail(this.id).then(res => {
                if(res) {
                    this.haggleInfo = res.data.bargain_goods
                    this.haggleInfo.sales_price = res.data.bargain_goods_sku.sales_price
                    this.haggleInfo.price = res.data.bargain_goods_sku.goods_sku.price
                    if(this.userId == res.data.bargain_user_goods_sku.user.id) {
                        this.$router.push({path:`/en/haggleDetail/${res.data.bargain_goods.goods_id}`,query:{haggle_id:this.id}})
                    }
                }
            })
        },
        rule() {
            this.ruleShow = !this.ruleShow
        },
        // mooby热销
        getHotGoods() {
            // 获取热门单品列表
            this.hloading = true
            hotGoods({page: this.page,is_page: 1,per_page: 20}).then(res => {
                this.hotProductList = this.hotProductList.concat(res.data.data)
                if (res.data.data.length == 0) {
                    this.noMore = true
                }
            }).finally(() => {
                this.hloading = false
            })
        },
        onLoad() {
            this.page ++
            this.getHaggleData()
        },
        getHaggleData() {
            haggle_list({page: this.page}).then(res => {
                if(res) {
                    this.goodsList = this.goodsList.concat(res.data.data)
                    if(this.goodsList.length >= res.data.total) {
                        this.finished = true
                    }
                    this.goodsList.forEach(item => {
                        if(item.bargain_user_goods_sku) {
                            item.show = true
                        }
                    })
                }
            }).finally(() => {
                this.loading = false
            })
        },
        
    },
    created() {
        if(this.$route.query.id) {
            this.id = this.$route.query.id
        }
        if(localStorage.getItem('userId')) {
            this.userId = localStorage.getItem('userId')
        }
        this.getData()
        this.isHaggle()
        // this.getHotGoods()
    }
}
</script>
<style lang="less" scope>
.m-haggle {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    position: relative;
    background-color: #FF5049;
    .header {
        width: 100%;
        height: 44px;
        text-align: center;
        line-height: 44px;
        position: fixed;
        top: 0;
        left: 0;
        color: #fff;
        font-size: 18px;
        z-index: 22;
    }
    .haggle-top {
        width: 100%;
        height: 246px;
        background: #efefef;
        position: relative;
        img {
            width: 100%;
            height: 100%;
        }
        .van-swipe {
            height: 100%;
            .van-swipe-item {
                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .haggle-content {
        width: 100%;
        display: flex;
        align-items: center;
        flex-flow: column;
        border-radius: 0 0 8px 8px;
        background: #fff;
        margin-bottom: 10px;
        .haggle-price {
            text-align: center;
            font-size: 14px;
            margin-bottom: 16px;
            color: #666;
        }
        .share-btn {
            font-size: 18px;
            width: 311px;
            height: 44px;
            line-height: 44px;
            border-radius: 22px;
            margin-bottom: 8px;
            background: linear-gradient(270deg, #FF4745 -13.18%, #FF9E6E 113.18%);
            text-align: center;
            color: #fff;
        }
        .buy {
            width: 336px;
            height: 69px;
            img {
                width: 336px;
                height: 69px;
            }
        }
    }
    .haggle-goods {
        margin: 0 auto 90px;
        padding: 46px 0 12px;
        border: 2px solid #fff;
        border-radius: 16px;
        width: 359px;
        position: relative;
        z-index: 10;
        background: #FEEACC;
        .haggle-title {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            color: #fff;
            font-size: 18px;
            font-weight: bold;
            width: 280px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: url('../../../assets/haggle/title.png') no-repeat;
            background-size: 100% 100%;
            img {
                width: 16px;
                height: 10px;
                &:nth-of-type(2) {
                    transform: rotate(180deg);
                }
            }
            span {
                margin: 0 8px;
            }
        }
        .m-pad {
            padding: 0 8px;
        }
        .goods-columns-1 {
            background: #fff;
            width: 100%;
            border-radius: 12px;
            background: #fff;
            margin-bottom: 12px;
            box-shadow: 0px 2px 16px rgba(204, 204, 204, 0.2);
            position: relative;
            overflow: hidden;
            &::after {
                content: '';
                display: inline-block;
                width: 81px;
                height: 143px;
                position: absolute;
                top: 8px;
                right: 0;
                background: url('../../../assets/haggle/Vector\ 20.png') no-repeat;
                background-size: 100% 100%;
                z-index: 1;
            }
            .m-goods {
                display: flex;
                position: relative;
                padding: 8px;
                z-index: 2;
                .haggling {
                    padding: 3px 8px;
                    position: absolute;
                    top: 8px;
                    left: 8px;
                    font-size: 12px;
                    color: #fff;
                    background: url('../../../assets/haggle/Rectangle 102.png') no-repeat;
                    background-size: 100% 100%;
                }
                &>.goods-img {
                    width: 110px;
                    height: 110px;
                    border-radius: 5px;
                    margin-right:8px;
                }
                .goods-info {
                    flex: 1;
                    display: flex;
                    flex-flow: column;
                    justify-content: space-between;
                    &>p {
                        font-size: 14px;
                        // letter-spacing: 0.31px;
                        font-weight: 400;
                        line-height: 18px;
                        color: #333;
                    }
                    .count {
                        display: flex;
                        font-size: 12px;
                        color: #EF4422;
                        border: 1px solid #EF4D2D;
                        width: 170px;
                        height: 16px;
                        align-items: center;
                        border-radius: 8px;
                        margin-top: 4px;
                        margin-bottom: 14px;
                        padding-left: 8px;
                        box-sizing: border-box;
                        span {
                            display: inline-block;
                            line-height: 14px;
                            margin-right: 5px;
                        }
                        .van-count-down {
                            height: 14px;
                            line-height: 14px;
                            color: #FF6666;
                            font-size: 12px;
                        }
                    }
                    .goods-price {
                        display: flex;
                        align-items: flex-end;
                        justify-content: space-between;
                        font-size: 12px;
                        &>div:nth-child(1) {
                            .down {
                                width: 70px;
                                text-align: center;
                                color: #fff;
                                font-size: 10px;
                                background: linear-gradient(315deg, #F03C18 0.05%, #F06448 100%);
                                border-radius: 2px;
                            }
                            p:nth-of-type(1){
                                color: #ED2A24;
                                margin-top: 4px;
                                &>span:nth-child(1){
                                    font-size: 18px;
                                }
                                &>span:nth-child(2){
                                    margin-top: 2px;
                                    margin-left: 5px;
                                    font-size: 10px;
                                    color: #999999;
                                    text-decoration:line-through;
                                }
                            }
                        }
                        .goods-btn {
                            a{
                                display: inline-block;
                                padding: 6px 16px;
                                line-height: 1;
                                background: linear-gradient(270deg, #FF4745 -13.18%, #FF9E6E 113.18%);
                                border-radius: 22px;
                                font-size: 14px;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
    .hot-sale {
        .hot-title {
            text-align: center;
            margin: 10px 0;
            font-size: 18px;
            img {
                width: 16px;
                height: 10px;
            }
        }
        .goods-price {
            img {
                width: 0 !important;
                height: 0 !important;
            }
        }
    }
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        .tip {
            width: 288px;
            height: 325px;
            position: relative;
            // color: #fff;
            text-align: center;
            background: #fff;
            border-radius: 12px;
            img {
                width: 100%;
                height: 100%;
                
            }
            .offPrice {
                position: absolute;
                width: 250px;
                font-size: 18px;
                left: 50%;
                top: 50%;
                transform: translate(-50%,-50%);
            }
            .coupon {
                position: absolute;
                bottom: 77px;
                width: 195px;
                font-size: 14px;
                left: 50%;
                transform: translateX(-50%);
            }
            .know {
                position: absolute;
                bottom: 16px;
                left: 50%;
                transform: translateX(-50%);
                width: 170px;
                height: 40px;
            }
        }
    }
    .van-overlay {
        z-index: 99 !important;
    }
    .goods {
        width: 100%;
        background: url('../../../assets/haggle/price-bg.png') no-repeat;
        background-size: 100%;
        overflow: hidden;
    }
    .goods-prices {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 13px;
        margin-bottom: 13px;
        color: #fff;
        .price-left {
            .title {
                font-size: 18px;
                font-weight: bold;
            }
            .price {
                font-size: 14px;
                &>span:nth-child(1) {
                    display: inline-block;
                    font-size: 20px;
                    font-weight: bold;
                    margin: 0 6px 0 3px;
                }
                &>span:nth-child(2) {
                    text-decoration:line-through;
                }
            }
        }
        .price-right {
            .over {
                font-size: 14px;
                margin-bottom: 5px;
                text-align: right;
            }
            .count {
                .block {
                    padding: 4px;
                    line-height: 1;
                    font-size: 10px;
                    color: #FF411B;
                    border-radius: 5px;
                    background: #fff;
                }
                .colon {
                    color: #fff;
                    font-weight: bold;
                }
            }
        }
    }
    .is-margin {
        width: 359px;
        background: #fff;
        margin:0 auto 8px;
        padding: 12px 8px;
        border-radius: 8px;
    }
    .goods-title {
        .title {
            display: flex;
            align-items: center;
            position: relative;
            padding-right: 50px;
            .rules {
                position: absolute;
                right: 0;
                top: 50%;
                transform: translateY(-50%);
                text-align: center;
                margin-left: 15px;
                background-color: #FDF6C4;
                border-radius: 50%;
                height: 35px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                &>p {
                    width: 35px;
                    font-size: 10px;
                    color: #666;
                }
            }
        }
        .hanggle {
            width: 100%;
            border-top: 1px solid #f2f2f2;
            display: flex;
            align-items: center;
            padding-top: 16px;
            margin-top: 16px;
            div {
                flex: 1;
                text-align: center;
                color: #0A1629;
                &>p:nth-child(1) {
                font-size: 14px;
                color: #999;
                margin-bottom: 5px;
                }
            }
        }
    }
}
</style>